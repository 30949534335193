<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" :can-cancel="false" />
        <div v-if="isLive" class="alert alert-danger" style="text-align: center; font-weight: bold;" role="alert">
            You are Managing the Live Server Data
        </div>
        <CRow>
            <CCol sm="8">
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-globe-alt"/><strong>  World {{ world.world_name }}</strong>
                        <div class="card-header-actions">
                            <CBadge :color="$config.flagClr(world.flags)">{{ $config.flagTxt(world.flags) }}</CBadge>
                        </div>
                    </CCardHeader>
                    <CCardBody>
                        <dl class="row">
                            <dt class="col-sm-3">ID</dt>
                            <dd class="col-sm-9">{{ world.world_id }}</dd>

                            <dt class="col-sm-3">Name</dt>
                            <dd class="col-sm-9">{{ world.world_name }}</dd>

                            <dt class="col-sm-3">Network</dt>
                            <dd class="col-sm-9">{{ world.ip }}:{{ world.port }}</dd>

                            <dt class="col-sm-3">Key</dt>
                            <dd class="col-sm-9">{{ world.world_key }}</dd>

                            <dt class="col-sm-3">Deployed</dt>
                            <dd class="col-sm-9">{{ isDeployed }}</dd>
                        </dl>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol sm="4">
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-settings"/><strong>  Update Access</strong>
                    </CCardHeader>
                    <CCardBody>
                    <CRow form class="form-group" key="flagform">
                        <CCol sm="9">
                            <div class="custom-control custom-checkbox" v-for="flag in flags" :key="flag.id">
                                <input class="custom-control-input" type="checkbox" :id="flag.id" :value="flag.id" v-model="selectedFlags">
                                <label class="custom-control-label" :for="flag.id">{{ flag.flag }}</label>
                            </div>
                        </CCol>
                    </CRow>
                    </CCardBody>
                    <CCardFooter>
                        <CButton color="primary" class="px-4" @click="updateWorld" :disabled="isUpdating">
                            <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                            {{ btnTxt }}
                        </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <slot name="header">
                            <CIcon name="cil-terrain"/><strong> Zones </strong>
                        </slot>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable
                                :key="zoneDataKey"
                                :items="zones"
                                :fields="zoneFields"
                                :hover="hover"
                                pagination >
                            <template #access="{item}">
                                <td>
                                    <CBadge :color="$config.flagClr(item.Flags)">{{ $config.flagTxt(item.Flags) }}</CBadge>
                                </td>
                            </template>
                            <template #status="{item}">
                                <td>
                                    <CBadge :color="$config.statusClr(active[item.SceneName])">{{ $config.statusTxt(active[item.SceneName]) }}</CBadge>
                                </td>
                            </template>
                            <template #run="{item}">
                                <td class="py-2">
                                    <div  v-if="isDeployed">
                                        <div v-if="active[item.SceneName] > 0">
                                            {{ active[item.SceneName] }} Instances
                                        </div>
                                        <CButton v-else
                                                    color="light"
                                                    square
                                                    size="sm"
                                                    @click="startZone(item.SceneName)">
                                            Start
                                        </CButton>
                                    </div>
                                    <div v-else>
                                        N/A
                                    </div>
                                </td>
                            </template>
                            <template #manage="{item}">
                                <td class="py-2">
                                    <CButton
                                            color="primary"
                                            variant="outline"
                                            square
                                            size="sm"
                                            @click="manageZone(item.id)">
                                        Manage
                                    </CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <slot name="header">
                            <CIcon name="cil-settings"/><strong> Configs </strong>
                        </slot>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable
                                :items="configs"
                                :key="configDataKey"
                                :fields="cFields"
                                :hover="hover"
                                pagination >
                            <template #data="{item}">
                                <td>
                                    <div v-if="isMotd(item)">
                                        <CTextarea
                                            :value.sync="item.data"
                                            horizontal
                                            rows="9" />
                                        <CButton color="primary" class="px-4" size="sm" @click="updateMOTD" :disabled="isUpdating">
                                            <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                                            {{ btnTxt }}
                                        </CButton>
                                    </div>
                                    <vue-json-pretty v-else 
                                            
                                            :data="item.data">
                                    </vue-json-pretty>
                                </td>
                            </template>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import VueJsonPretty from 'vue-json-pretty'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import 'vue-json-pretty/lib/styles.css';

    export default {
        name: "World",
        components: {
            VueJsonPretty,
            Loading
        },
        props: ['id'],
        data(){
            return {
                world: {},
                isDeployed: false,
                zones: [],
                active: [],
                flags: [],
                selectedFlags: [],
                zoneFields: [
                    { key: "zoneId", label: "ID" },
                    { key: 'sceneName', label: 'Scene' },
                    { key: 'displayName', label: 'Name' },
                    { key: 'address', label: 'IP' },
                    { key: 'defaultPopulationThreshold', label: 'Default Pop' },
                    { key: 'flags', label: 'Status' },
                    { key: 'run', label: '' },
                    { key: 'manage', label: '' },
                ],
                configs: [],
                cFields: ['key', 'data'],
                hover: true,
                isUpdating : false,
                isLoading : false,
                worldLoading : false,
                activeLoading: false,
                btnTxt: "Update",
                zoneDataKey: 0,
                configDataKey: 0,
                motd: ""
            }
        },
        watch: {
            isUpdating: function(){
                this.btnTxt = (this.isUpdating ? 'Please Wait...' : 'Update');
            }
        },
        computed: {
            isLive: function () {
                return (localStorage.env === "live");
            }
        },
        methods: {
            getWorld() {
                this.worldLoading = true;
                this.isLoading = true;

                this.$http.get(this.$config.gameapi + '/World/' + localStorage.env + '/' + this.id, { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.world = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.getZones();
                });
            },
            getZones() {
                this.$http.get(this.$config.gameapi + '/Zone/' + localStorage.env + '/world/' + this.id, { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.zones = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.getConfigs();
                });
            },
            getConfigs() {
                this.$http.get(this.$config.gameapi + '/World/' + localStorage.env + '/configs/' + this.id, { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.configs = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function () {
                    this.getFlags();
                });
            },
            getFlags() {
                this.isLoading = true;
                this.flags = [];

                this.$http.get(this.$config.gmapi + '/GameFlag/set?incSub=true', { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.flags = jsonData;
                    
                    for (let i = 0; i < this.flags.length; i++) {
                        if ((this.world.flags & this.flags[i].id) == this.flags[i].id)
                            this.selectedFlags.push(this.flags[i].id);
                    }

                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.worldLoading = false;
                    if (!this.activeLoading) {
                        this.isLoading = false;
                    }
                });
            },
            getActive() {
                this.activeLoading = true;
                this.isLoading = true;
                this.active = {};

                this.$http.get(this.$config.zc[localStorage.env] + '/' + localStorage.env + '/procs', { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.isDeployed = jsonData.IsDeployed;

                    for (let i = 0; i < this.zones.length; i++) {
                        this.active[this.zones[i].SceneName] = 0;
                    }

                    for (let i = 0; i < jsonData.RunningZones.length; i++) {
                        this.active[jsonData.RunningZones[i].Zone]++;
                    }
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.zoneDataKey++;
                    this.activeLoading = false;
                    if (!this.worldLoading) {
                        this.isLoading = false;
                    }
                });
            },
            manageZone(id) {
                this.$router.push({ name: 'Zone', params: { id: id } })
            },
            startZone(scene) {
                this.isLoading = true;
                this.$http.post(this.$config.zc[localStorage.env] + '/' + localStorage.env + '/zones/start/' + scene, null,{ headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    if (jsonData.status){
                        setTimeout(() => { this.getActive(); }, 2000);
                    } else {
                        this.isLoading = false;
                        this.$root.$emit('sendMsg', "API Error", "danger", "Unable to start Zone: " + jsonData.message );
                    }
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                });
            },
            stopZone(pid) {
                this.isLoading = true;
                this.$http.post(this.$config.zc[localStorage.env] + '/' + localStorage.env + '/zones/kill/' + pid, null,{ headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    if (jsonData.status){
                        setTimeout(() => { this.getActive(); }, 2000);
                    } else {
                        this.isLoading = false;
                        this.$root.$emit('sendMsg', "API Error", "danger", "Unable to kill Zone: " + jsonData.message );
                    }
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                });
            },
            updateWorld(e){
                e.preventDefault();
                const arrSum = this.selectedFlags.reduce((a,b) => a + b, 0);
                this.world.flags = arrSum;
                this.isUpdating = true;
                this.$http.put(this.$config.gameapi + '/World/' + localStorage.env + '/', this.world, {headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.world = jsonData;
                }).catch(function (error) {
                    this.$root.$emit('onErr', error);
                    this.isUpdating = false;
                }).finally(function () {
                    this.isUpdating = false;
                }); 
            },
            updateMOTD(e){
                e.preventDefault();
                this.isUpdating = true;
                this.$http.put(this.$config.gameapi + '/World/' + localStorage.env + '/motd', {
                    world_id: this.world.world_id,
                    message: this.motd
                }, {headers: this.$config.getHeaders() }).then(response => {
                    return
                }).catch(function (error) {
                    this.$root.$emit('onErr', error);
                    this.isUpdating = false;
                }).finally(function () {
                    this.isUpdating = false;
                    this.getConfigs();
                });
            },
            isMotd(c) {
                if (c.key === 'motd') {
                    this.motd = c.data;
                    return true;
                } else {
                    return false;
                }

            }
        },
        created() {
            this.getWorld();
            this.getActive();
        }
    }
</script>

<style scoped>

</style>
